<template>
  <div class="suggest-learn">
    <ul>
      <li v-for="item of list" :key="item.id">
        <img :src="item.img_logo" alt="" />
        <p class="class-name">{{ item.name }}</p>
        <Label :label="item.label_id"></Label>
      </li>
    </ul>
  </div>
</template>

<script>
import Label from '../components/label'

import { apiCourseRecomend } from '@/api/IELTScourse'

export default {
  components: {
    Label
  },
  filters: {
    lableText(val) {
      return ['', 'Speaking', 'Listening', 'Writing', 'Reading'][val]
    }
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const { data } = await apiCourseRecomend()
      this.list = data
    }
  }
}
</script>

<style scoped lang="scss">
.suggest-learn {
  > ul {
    display: flex;
    margin: 0 -10px;
    > li {
      margin: 10px;
      > img {
        width: 166px;
        height: 90px;
        border-radius: 5px;
        object-fit: cover;
      }
      .class-name {
        color: #ffffff;
        margin: 6px 0;
      }
    }
  }
}
</style>
