<template>
  <el-dialog
    class="open-vip-dialog"
    title="购买课程"
    :visible.sync="visible"
    width="542px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div class="open-vip-dialog-content" v-loading="orderLoading">
      <template v-if="currentInfo.courseType == '11'">
        <div class="title" v-if="currentInfo">
          {{ currentInfo.name }}
        </div>
      </template>
      <template v-else>
        <div class="title-wrap" v-if="currentInfo">
          <div>
            <span>{{ currentInfo.name }}</span>
            <span class="rebate"
              >{{ Math.round(currentInfo.rebate * 100) }}折</span
            >
          </div>
          <div>
            <span class="origin-price">￥{{ currentInfo.money_total }}</span>
            <span class="real-price">
              ￥{{ (currentInfo.money_total * currentInfo.rebate).toFixed(2) }}
            </span>
          </div>
        </div>
      </template>

      <div class="pay-list">
        <div>支付方式</div>
        <div class="right" v-loading="urlLoading">
          <el-radio-group v-model="payType">
            <el-radio class="radio" :label="'wechat'"
              ><i class="f-icon icon-weixin pay-icon" />微信支付</el-radio
            >
            <el-radio class="radio" :label="'alibaba'"
              ><i class="f-icon icon-zhifubao pay-icon" />支付宝</el-radio
            >
            <el-radio class="radio" :label="'credit'"
              ><i class="f-icon icon-credit pay-icon" />点券</el-radio
            >
          </el-radio-group>
          <div class="qrcode-content">
            <template v-if="payType === 'alibaba'">
              <div class="qrcode-content-text alibaba">
                <div class="qrcode-content-num" v-if="!isHelp">
                  <div v-if="currentInfo.rebate !== 1" class="old">
                    ￥{{ currentInfo.money_total }}
                  </div>
                  <div>
                    ￥{{
                      (currentInfo.money_total * currentInfo.rebate).toFixed(2)
                    }}
                  </div>
                </div>
                <template v-if="isHelp">
                  <div>扫码添加PTEGO小助手反馈问题</div>
                  <img :src="helperInfo.site_val" />
                  <a
                    class="buy-link-text"
                    :href="alipayUrl"
                    target="_blank"
                    @click="clickPay"
                    >再次支付</a
                  >
                </template>
                <template v-else-if="isClickPay">
                  <div class="tips-text">请在新打开的页面完成付款</div>
                  <div class="btn-list">
                    <div class="buy-link-text" @click="clickHelp">
                      支付遇到问题
                    </div>
                    <div class="buy-link" @click="getPayResult(true)">
                      支付成功
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>即将跳转支付宝平台完成支付</div>
                  <a
                    class="buy-link"
                    :href="alipayUrl"
                    target="_blank"
                    @click="clickPay"
                    >去支付</a
                  >
                  <div class="tips">如解锁后本课程有更新,将自动免费获取</div>
                </template>
              </div>
            </template>
            <template v-if="payType === 'wechat'">
              <div class="qrcode-wrap">
                <div
                  class="qrcode-block"
                  :class="{ 'qrcode-overtime': overtime }"
                >
                  <div id="qrcode" ref="qrcode" class="qrcode"></div>
                </div>
                <i
                  class="f-icon icon-loading"
                  v-if="overtime"
                  @click="createUrlQrcode"
                />
              </div>
              <div class="qrcode-content-text">
                <div class="qrcode-content-num">
                  <div>
                    ￥{{
                      (currentInfo.money_total * currentInfo.rebate).toFixed(2)
                    }}
                  </div>
                  <div v-if="currentInfo.rebate !== 1" class="old">
                    ￥{{ currentInfo.money_total }}
                  </div>
                </div>
                <div>打开手机微信</div>
                <div>扫一扫完成支付</div>
                <div class="overtime">
                  <span v-if="overtime">
                    <i class="f-icon icon-shijian" />付款超时
                  </span>
                </div>
                <div class="tips">如解锁后本课程有更新<br />将自动免费获取</div>
              </div>
            </template>
            <template v-if="payType === 'credit'">
              <div class="credit-pay">
                <div class="total">
                  {{ currentInfo.money_ticket_total }}点券
                </div>
                <div class="current">
                  点券余额 <span>{{ userInfo.credit || 0 }}</span>
                </div>
                <el-button
                  class="btn"
                  type="primary"
                  :disabled="currentInfo.money_ticket_total > userInfo.credit"
                  @click="handleCreditBuy"
                  >购买</el-button
                >
                <div class="tip">如解锁后本课程有更新，将自动免费获取</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { apiVipPayResult } from '@/api/api'
import {
  apiCourseWechatPay,
  apiCourseAlipay,
  apiCourseOrder,
  apiCoursePayCredit
} from '@/api/IELTScourse'
import QRCode from 'qrcodejs2'

export default {
  props: {
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      payType: 'wechat',

      qrcode: '',
      qr: null,

      currentInfo: {},
      orderInfo: null,

      orderLoading: false,
      urlLoading: false,

      // 是否超时
      overtime: false,
      overtimeTimer: null,

      isClickPay: false,
      isHelp: false,
      //判断是否已经点击了支付
      showType: false
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearTimeout(this.overtimeTimer)
  },
  watch: {
    payType() {
      this.createUrlQrcode()
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
      helperInfo: (state) => state.helperInfo
    }),
    alipayUrl() {
      return this.orderInfo
        ? `${process.env.API_HTTP_ADDRESS}api/pay/ali-h5-unify?order_id=${this.orderInfo.order_id}`
        : ''
    }
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    open({
      name,
      courseType,
      courseIds,
      money_total,
      money_ticket_total,
      rebate = 0.9
    }) {
      this.visible = true

      this.currentInfo = {
        name,
        courseType,
        courseIds,
        money_total,
        money_ticket_total,
        rebate
      }

      this.createOrder()
    },

    clickHelp() {
      this.isHelp = true
    },
    clickPay() {
      this.showType = true
      this.isHelp = false
      this.isClickPay = true
    },
    handleClose(done) {
      this.onClose()

      done && done()
    },
    createOrder() {
      this.orderLoading = true
      const { courseType, courseIds, money_total, rebate } = this.currentInfo
      apiCourseOrder({
        course_type: courseType,
        course_list: courseIds.join(','),
        rebate: rebate,
        money_total: money_total
      })
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.orderInfo = res.data
          this.createUrlQrcode()
        })
        .finally(() => {
          this.orderLoading = false
        })
    },
    createUrlQrcode() {
      clearInterval(this.timer)
      clearTimeout(this.overtimeTimer)

      this.overtime = false
      this.isHelp = false
      this.isClickPay = false
      this.closeCode()
      if (this.payType === 'alibaba') {
        this.timer = setInterval(this.getPayResult, 3000)
      } else if (this.payType === 'wechat') {
        this.urlLoading = true
        apiCourseWechatPay({
          order_id: this.orderInfo.order_id
        })
          .then((res) => {
            if (res.code !== 200) {
              return
            }
            this.qrcode = res.data.qr_code_url
            this.$nextTick(() => {
              this.createQrcode()
            })
            // 付款超时
            this.overtimeTimer = setTimeout(this.overtimeCode, 180000)

            this.timer = setInterval(this.getPayResult, 3000)
          })
          .finally(() => {
            this.urlLoading = false
          })
      }
    },
    // 生成二维码
    createQrcode() {
      this.qr = new QRCode('qrcode', {
        width: 130,
        height: 130,
        text: this.qrcode
      })
    },
    closeCode() {
      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = ''
      }
    },
    overtimeCode() {
      this.overtime = true
    },
    getPayResult(check) {
      console.log('======= get pay result')
      apiVipPayResult({
        order_id: this.orderInfo.order_id
      }).then((res) => {
        if (
          this.showType &&
          this.payType == 'alibaba' &&
          !res.data.pay_status
        ) {
          this.$notify({
            title: '提示',
            message: '支付失败',
            type: 'warning'
          })
          this.handleClose()
          clearInterval(this.timer)
          return
        }
        console.log(
          '======= get pay result:',
          JSON.stringify(this.orderInfo),
          JSON.stringify(res),
          res.data.pay_status
        )
        if (res.code !== 200) {
          return
        }
        if (res.data.pay_status) {
          clearInterval(this.timer)
          this.handleClose()
          this.$emit('successDialog')
          this.$layer.msg("<div class='success-icon'></div><div>支付成功</div>")
          setTimeout(() => {
            window.location.reload()
          }, 1300)
        } else if (check) {
          clearInterval(this.timer)
          this.handleClose()
          this.$layer.msg('<div>支付失败</div>')
        }

        this.GetUserInfo()
      })
    },
    handleCreditBuy() {
      apiCoursePayCredit({
        order_id: this.orderInfo.order_id
      }).then((res) => {
        console.log(res)
        if (res.code !== 200) {
          return
        }

        this.$emit('successDialog')
        this.$message.success('支付成功')
        setTimeout(() => {
          window.location.reload()
        }, 800)
      })
    }
  }
}
</script>

<style lang="scss">
.open-vip-dialog {
  .el-dialog__header {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #ebebf2;
    align-items: center;
    .el-dialog__headerbtn {
      position: inherit;
    }
    .el-dialog__title {
      font-size: 14px;
      font-weight: bold;
      color: #a4afb7;
    }
  }
  .open-vip-dialog-content {
    margin: 40px 0;
  }
  .el-radio {
    margin-right: 44px;
  }
  .el-radio__inner {
    border: 1px solid #c3daff;
  }
  .el-dialog__body {
    margin-top: -30px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #3b86ff;
    background: #fff;
    &::after {
      width: 8px;
      height: 8px;
      background: #3b86ff;
    }
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #4d4f5c;
  }
  .el-radio__label {
    color: #a4afb7;
  }
  .qrcode-content {
    display: flex;
    width: 360px;
    padding: 30px 30px;
    background: #fafafa;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 20px;
  }
  .qrcode-content-text {
    &.alibaba {
      text-align: center;
      width: 100%;
      .tips-text {
        margin-top: 17px;
      }
      .qrcode-content-num {
        display: block;
        height: 58px;
        margin: -10px 0 10px;
        .old {
          margin: 0 0 10px 0;
        }
      }
      img {
        margin: 7px 0 17px;
      }
    }
  }
  .buy-link {
    display: inline-block;
    color: #fff;
    background: #3b86ff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    width: 160px;
    height: 32px;
    line-height: 32px;
    text-decoration: none;
    margin: 7px 0 9px;
    &:hover {
      opacity: 0.8;
    }
  }
  .buy-link-text {
    display: block;
    width: 114px;
    cursor: pointer;
    margin: 0 auto;
    color: #3b86ff;
    text-decoration: none;
  }
  .btn-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    margin-top: 30px;
    .buy-link {
      width: 114px;
    }
  }
  .qrcode-content-num {
    display: flex;
    margin: 8px 0 9px;
    font-size: 20px;
    color: #3b86ff;
    align-items: center;
  }
  .old {
    margin: 0 0 0 15px;
    font-size: 14px;
    color: #bcbccb;
    text-decoration: line-through;
  }
  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #d5d5d5;
  }
  .overtime {
    margin: 9px 0 8px;
    color: #ff606d;
    font-size: 12px;
    height: 17px;
    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .qrcode-wrap {
    position: relative;
    i {
      position: absolute;
      color: #fff;
      font-size: 26px;
      top: 50%;
      left: 50%;
      margin: -13px 0 0 -26px;
      cursor: pointer;
    }
  }
  .qrcode-block {
    position: relative;
    width: 142px;
    height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-right: 23px;
    border-radius: 5px;
    &.qrcode-overtime {
      filter: brightness(0.6);
    }
  }
  .icon-zhifubao {
    color: #3b86ff;
  }
  .icon-weixin {
    color: #29bc1e;
  }
  .icon-credit {
    color: #ff9800;
  }
  .qrcode {
    width: 130px;
    height: 130px;
  }
  .pay-list {
    display: flex;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
  }
  .title-wrap {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
    }
    .rebate {
      display: inline-block;
      background-color: #ffdfe2;
      padding: 2px 8px;
      color: #ff606d;
      font-size: 12px;
      border-radius: 10px;
      margin-left: 10px;
    }
    .origin-price {
      font-size: 12px;
      color: #bfbfbf;
      text-decoration: line-through;
      margin-right: 10px;
    }
  }
  .right {
    margin-left: 15px;
  }
  .pay-icon {
    font-size: 20px;
    margin-right: 5px;
    vertical-align: bottom;
  }
  .credit-list {
    display: flex;
    margin-bottom: 20px;
    .credit-item {
      width: 100px;
      height: 32px;
      margin-right: 29px;
      line-height: 32px;
      font-size: 18px;
      background: #ebebf2;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #ffffff;
        background: #3b86ff;
      }
    }
  }
  .credit-pay {
    margin: 0 auto;
    text-align: center;
    .total {
      font-size: 20px;
      font-weight: bold;
      color: #3b86ff;
    }
    .current {
      margin-top: 20px;
      > span {
        color: #3b86ff;
      }
    }
    .btn {
      margin-top: 6px;
      background-color: #3b86ff;
      border-color: #3b86ff;
      min-width: 160px;
      height: 32px;
      padding: 0;
      &:disabled {
        background-color: #bfbfbf;
        border-color: #bfbfbf;
      }
    }
    .tip {
      font-size: 12px;
      color: #bfbfbf;
      margin-top: 16px;
    }
  }
}
</style>
