export const smartNumStr = (num) => {
  // 1、真实语音条数100以下，显示真实数字
  // 2、真实语音条数100以上，乘以系数
  // 3、显示999+、2000+、3000+、4000+、5000+、1w+这几个段，1000以内显示数字（乘以系数后的）

  // 1、真实语音条数100以下，显示真实数字
  if (num < 100) {
    return `${num}`
  }

  const SMART_RATE = 2.356
  const smartNum = Math.round(SMART_RATE * num)

  if (smartNum < 1000) {
    return `${smartNum}`
  }
  if (smartNum < 2000) {
    return `999+`
  }
  if (smartNum < 10000) {
    return `${Math.floor(smartNum / 1000)}000+`
  }
  return '1w+'
}

export const numToCn = (num) => {
  const unit = ['', '十', '百', '千', '万', '十', '百', '千']
  const cnNum = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  return unit
    .map((un, index) => {
      return {
        nn: Number(
          String(Math.floor(num / Math.pow(10, index)))
            .split('')
            .pop()
        ),
        un
      }
    })
    .reverse()
    .filter((m) => m.nn)
    .reduce((str, item) => {
      return str + cnNum[item.nn] + item.un
    }, '')
}
