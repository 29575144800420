<template>
  <div class="chapter-list">
    <p class="title-2" v-if="info.is_vip != 1 && type1List.length > 0">
      基础讲解
    </p>
    <ul>
      <ChapterItem
        v-for="item of type1List"
        :key="item.id"
        :item="item"
        :onDetail="handleDetail"
      />
    </ul>

    <p
      class="title-2"
      style="margin-top: 20px"
      v-if="info.is_vip != 1 && type2List.length > 0"
    >
      实战带练
    </p>
    <ul>
      <ChapterItem
        v-for="item of type2List"
        :key="item.id"
        :item="item"
        :onDetail="handleDetail"
      />
    </ul>
  </div>
</template>

<script>
import ChapterItem from './chapterItem'
import completionImg from '@/assets/class/duigou.png'
import { apiChapterList } from '@/api/IELTScourse'

export default {
  components: {
    ChapterItem
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    info: {
      type: Object,
      default: () => ({})
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    type1List() {
      return this.list.filter((m) => m.type === 1)
    },
    type2List() {
      return this.list.filter((m) => m.type === 2)
    },
    completionImg() {
      return completionImg
    }
  },
  created() {
    // this.fetchList()
  },
  methods: {
    async fetchList() {
      const { skillId } = this.$route.query
      const { data } = await apiChapterList({
        course_id: skillId
      })
      const list = data || []
      this.type1List = list.filter((m) => m.type === 1)
      this.type2List = list.filter((m) => m.type === 2)
    },

    handleDetail(item) {
      this.$router.push({
        name: 'IELTSchapterDetail',
        query: {
          skillId: item.course_id,
          chapterId: item.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.chapter-list {
  .title-2 {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 13px;
  }

  > ul {
  }
}
</style>
