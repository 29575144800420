<template>
  <div class="wrap">
    <div class="space left"></div>
    <div class="skill-chapter">
      <Nav :cateSub="info.cate_sub" />
      <Detail :onInit="handleInit" />
    </div>
    <div class="space right"></div>
  </div>
</template>

<script>
import Nav from '../SkillIndex/nav'
import Detail from './detail'

export default {
  components: {
    Nav,
    Detail
  },
  data() {
    return {
      info: {}
    }
  },
  methods: {
    handleInit(info) {
      this.info = info
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  min-height: calc(100vh - 66px);
  .space {
    flex: 1;
    &.left {
      background-color: #f8f8f8;
    }
    &.right {
      background-color: #4a4a58;
    }
  }
  .skill-chapter {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
}
</style>
